.about__intro {
  width: 100%;
  box-sizing: border-box;
  padding: var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--orange);

  @media (--laptop) {
    padding: var(--margin)
      calc(var(--columns-4) + var(--margin) + var(--gutter)) var(--margin)
      var(--margin);
  }
}
