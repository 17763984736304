.works {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;

  @media (--laptop) {
    padding: var(--margin);
  }

  &__work {
    width: var(--columns-2);
    height: var(--columns-2);
    flex-shrink: 0;
    position: relative;

    @media (--laptop) {
      width: var(--columns-4);
      height: var(--columns-4);
    }

    &:nth-child(7n + 1) {
      margin-top: calc(var(--column) + var(--gutter));
      margin-left: calc(var(--columns-3) + var(--gutter) + var(--margin));

      @media (--laptop) {
        align-self: flex-start;
        margin-left: 0;
      }
    }

    &:nth-child(7n + 2) {
      margin-top: calc(var(--column) + var(--gutter));
      margin-left: var(--margin);

      @media (--laptop) {
        margin-left: calc(var(--columns-10) + var(--gutter) * 2);
      }

      .works__work__title {
        right: calc(100% + var(--gutter));
        left: auto;
      }
    }

    &:nth-child(7n + 3) {
      margin-left: var(--gutter);
      margin-top: calc(var(--column) + var(--gutter));

      @media (--laptop) {
        margin-left: calc(var(--columns-2) + var(--gutter) * 2);
      }

      .works__work__title {
        top: calc(100% + var(--gutter));
        left: auto;
      }
    }

    &:nth-child(7n + 4) {
      margin-top: calc(var(--column) + var(--gutter));
      margin-left: calc(var(--columns-2) + var(--gutter) + var(--margin));

      @media (--laptop) {
        margin-left: calc(var(--columns-6) + var(--gutter));
        margin-right: calc(var(--columns-14) + var(--gutter));
      }
    }

    &:nth-child(7n + 5) {
      margin-top: calc(var(--column) + var(--gutter));
      margin-left: var(--margin);

      @media (--laptop) {
        margin-left: calc(var(--columns-2) + var(--gutter));
      }

      .works__items__wrapper__item__title {
        right: calc(100% + var(--gutter));
        left: auto;
      }
    }

    &:nth-child(7n + 6) {
      margin-top: calc(var(--column) + var(--gutter));
      margin-left: calc(var(--columns-1) + var(--gutter) * 2);

      @media (--laptop) {
        margin-left: calc(var(--columns-14) + var(--gutter) * 2);
      }

      .works__work__title {
        top: calc(100% + var(--gutter));
        left: auto;
      }
    }

    &:nth-child(7n + 7) {
      margin-top: calc(var(--column) + var(--gutter));
      margin-left: calc(var(--columns-1) + var(--gutter) + var(--margin));

      @media (--laptop) {
        margin-left: calc(var(--columns-14) + var(--gutter));
        margin-right: calc(var(--columns-6) + var(--gutter));
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      will-change: filter;
      transition: filter var(--transition-duration--fast)
        var(--transition-ease--out-quint);
    }

    &__title {
      position: absolute;
      top: 0%;
      left: calc(100% + var(--gutter));
      opacity: 0;
    }

    &:hover {
      .works__work__image {
        @media (--laptop) {
          filter: blur(16px);
        }
      }

      .works__work__title {
        @media (--laptop) {
          opacity: 1;
        }
      }
    }
  }
}
