main {
}

section,
.footer {
  @media (--laptop) {
    padding: 8.8rem var(--margin) 0;
  }

  & + section {
    margin-top: 12rem;

    @media (--laptop) {
      margin-top: 16rem;
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.color-trigger {
  width: 100%;
  height: 25vh;
}

.calendar-date-component {
  background-color: var(--green);
  border: 1px solid currentColor;
  box-sizing: border-box;
  padding: 0 0.32rem;
  border-radius: 1rem;
}
