.intro {
  width: 100%;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;

  &__wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: var(--margin);

    @media (--laptop) {
      padding: var(--margin);
    }
  }

  &__items {
    width: 100%;
    position: relative;
    height: calc(100vh - var(--margin));
    overflow-wrap: break-word;

    @media (--laptop) {
      height: calc(100vh - 7.2rem);
    }

    p {
      overflow-wrap: break-word;
      display: inline;
    }
  }
}
