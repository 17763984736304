.paperbar {
  width: 100%;
  overflow: hidden;
  position: relative;
  color: var(--orange);
  display: flex;
  flex-direction: column;
  padding: var(--margin);
  box-sizing: border-box;

  @media (--laptop) {
    padding: 8rem var(--margin);
  }

  &::before {
    content: "";
    width: calc(100% - (2 * var(--margin)));
    height: 1px;
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: var(--margin);
  }

  &__video {
    width: 100%;
    height: 0;
    padding-bottom: 64%;
    position: relative;

    @media (--laptop) {
      width: var(--columns-13);
      padding-bottom: 40%;
      margin-right: calc(var(--column) + var(--gutter) * 2);
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__works {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;

    &__work {
      width: var(--columns-2);
      height: var(--columns-2);
      flex-shrink: 0;
      position: relative;

      @media (--laptop) {
        width: var(--columns-4);
        height: var(--columns-4);
      }

      &:nth-child(7n + 1) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: calc(var(--columns-3) + var(--gutter));

        @media (--laptop) {
          align-self: flex-start;
          margin-left: 0;
        }
      }

      &:nth-child(7n + 2) {
        margin-top: calc(var(--column) + var(--gutter));

        @media (--laptop) {
          margin-left: calc(var(--columns-10) + var(--gutter) * 2);
        }

        .works__work__title {
          right: calc(100% + var(--gutter));
          left: auto;
        }
      }

      &:nth-child(7n + 3) {
        margin-left: var(--gutter);
        margin-top: calc(var(--column) + var(--gutter));

        @media (--laptop) {
          margin-left: calc(var(--columns-2) + var(--gutter) * 2);
        }

        .works__work__title {
          top: calc(100% + var(--gutter));
          left: auto;
        }
      }

      &:nth-child(7n + 4) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: calc(var(--columns-2) + var(--gutter));

        @media (--laptop) {
          margin-left: calc(var(--columns-6) + var(--gutter));
          margin-right: calc(var(--columns-14) + var(--gutter));
        }
      }

      &:nth-child(7n + 5) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: var(--margin);

        @media (--laptop) {
          margin-left: calc(var(--columns-2) + var(--gutter));
        }

        .works__items__wrapper__item__title {
          right: calc(100% + var(--gutter));
          left: auto;
        }
      }

      &:nth-child(7n + 6) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: calc(var(--columns-1) + var(--gutter) * 2);

        @media (--laptop) {
          margin-left: calc(var(--columns-14) + var(--gutter) * 2);
        }

        .works__work__title {
          top: calc(100% + var(--gutter));
          left: auto;
        }
      }

      &:nth-child(7n + 7) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: calc(var(--columns-1) + var(--gutter));

        @media (--laptop) {
          margin-left: calc(var(--columns-14) + var(--gutter));
          margin-right: calc(var(--columns-6) + var(--gutter));
        }
      }

      &__image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        will-change: filter;
        transition: filter var(--transition-duration--fast)
          var(--transition-ease--out-quint);
      }

      &__title {
        position: absolute;
        top: 0%;
        left: calc(100% + var(--gutter));
        opacity: 0;
      }

      &:hover {
        .paperbar__works__work__image {
          @media (--laptop) {
            filter: blur(16px);
          }
        }

        .works__work__title {
          @media (--laptop) {
            opacity: 1;
          }
        }
      }
    }
  }
}
