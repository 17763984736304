:root {
  --font-heading: "Museo Slab 500", serif;
  --font-body: "Montserrat", sans-serif;
}

h1 {
  font-family: var(--font-heading);
  font-weight: normal;
  font-style: normal;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 4rem;
}

h3,
.h3 {
  font-size: 3.2rem;

  @media (--laptop) {
    font-size: 4.8rem;
  }
}

h2,
.h2,
h3,
.h3 p,
.p1,
.p2,
.p3,
.p4,
.p5,
.p6 {
  font-family: var(--font-body);
}

.p1 {
  font-size: 4rem;

  @media (--laptop) {
    font-size: 7.2rem;
    line-height: 7.2rem;
  }
}

.p2 {
  font-size: 2.4rem;

  @media (--laptop) {
  }
}

.p3 {
  font-size: 2.6rem;
}

.p4 {
  font-size: 2.2rem;
}

.p5 {
  font-size: 1.6rem;
}

.p6 {
  font-size: 12rem;

  @media (--laptop) {
    font-size: 40rem;
  }
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}
